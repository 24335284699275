/* eslint-disable react/prop-types */
import React from 'react'
import { Link } from 'react-router-dom'
import { IconMoodSmile, IconBell } from '@tabler/icons'
import _ from 'lodash'
import * as Storage from '../../storage'
import { logout } from '../../utils'
import sonnt from '../../assets/img/avatar/avatar-girl-svgrepo-com.svg';
import { PATH_ROOT } from '../../config';

/**
 * Header conponent
 * Takes in the following props
 * @param {*} props 
 * @returns 
 */
const Header = () => {
    const userInfo = JSON.parse(localStorage.getItem(Storage.USER_INFO));
    const userName = _.upperFirst(userInfo.first_name) + ' ' + _.upperFirst(userInfo.last_name);
    const avatarName = userInfo.avatarName ? `${PATH_ROOT + '/images/avatars/' + userInfo.avatarName}` : sonnt

    return (<header className="navbar navbar-expand-md navbar-light d-none d-lg-flex d-print-none">
        <div className="container-fluid">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="navbar-nav flex-row order-md-last">
                <div className="nav-item dropdown d-none d-md-flex me-3">
                    <Link to={"#"} className="nav-link px-0 text-muted" data-bs-toggle="dropdown" tabIndex="-1" aria-label="Show notifications">
                        <IconBell/>
                        <span className="badge bg-red"></span>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-end dropdown-menu-card">
                        <div className="card">
                            <div className="card-body">
                                Error happened<IconMoodSmile/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nav-item dropdown">
                    <Link to={"#"} className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
                        <span className="avatar avatar-sm" style={{ backgroundImage: "url(" + avatarName + ")"}}></span>
                        <div className="d-none d-xl-block ps-2">
                            <div className="">{userName}</div>
                            <div className="mt-1 small text-muted">UI Designer</div>
                        </div>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                        <Link to={'#'} className="dropdown-item">Set status</Link>
                        <Link to={'/profile'} className="dropdown-item">Profile & account</Link>
                        {userInfo.role >= 2 && <Link to={'/users'} className="dropdown-item">User management</Link>}
                        {userInfo.role === 4 && <Link to={'/entities'} className="dropdown-item">Entity</Link>}
                        <div className="dropdown-divider"></div>
                        <span to={'#'} className="dropdown-item cl-pointer" onClick={() => { logout() }}>Logout</span>
                    </div>
                </div>
            </div>
            <div className="collapse navbar-collapse" id="navbar-menu"></div>
        </div>
    </header>)};

export default React.memo(Header);