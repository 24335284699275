/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { useForm } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import _ from 'lodash';
import { IconPlus, IconX, IconDownload } from '@tabler/icons';
import { postTopic } from './services';
const AddTopic = ({ fetchDataTopic }) => {
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const onSubmit = async (data) => {
        let dataTopic = {
            name: data.name
        }
        dataTopic = _.pickBy(dataTopic, v => !_.isNull(v) && !_.isUndefined(v))
        const response = await postTopic(dataTopic);
        if (response.error) {
            toast.error(response.errMessage);
            setError('name');
        } else {
            toast.success('Successfully');
            fetchDataTopic();
            setShow(false);
        }
    }

    return (
        <>
            <Button variant='primary' onClick={handleShow}>
                <IconPlus/> Add Subject
            </Button>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add a new subject</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title<span className='required ms-1'>(*)</span></Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Subject'
                                autoFocus
                                name='name'
                                className={errors.name ? 'input-error': '' }
                                {...register("name", { required: true })}
                                aria-invalid={errors.name ? "true" : "false"} 
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='default' type='button' onClick={handleClose}><IconX />Cancel</Button>
                    <Button variant='primary ms-auto' type='submit' onClick={handleSubmit(onSubmit)}><IconDownload/>Save</Button>
                </Modal.Footer>
            </Modal>
            <Toaster
                position='top-center'
                reverseOrder={false}
            />
        </>
    );
}

export default AddTopic;