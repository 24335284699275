/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import _ from 'lodash';
const App = ({ attributes, setHeaders, keyHeader }) => {
    const [listAttribute, setListAttribute] = useState(attributes);
    useEffect(() => {
        if (!_.isUndefined(attributes) && Array.isArray(attributes) && !_.isEmpty(attributes)) {
            const dataA = _.map(attributes, item => {
                return { ...item, value: item.code, keyHeader: keyHeader }
            })
            setListAttribute(dataA)
        }
    }, [attributes]);
    const handleSelect = (value, option) => {
        setHeaders(option);
    }
    return <>
        <Select
            showSearch
            style={{ minWidth: 200 }}
            dropdownStyle={{ zIndex: 9999 }}
            placeholder='Search to Select'
            optionFilterProp='label'
            filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onSelect={handleSelect}
            options={listAttribute}
        />
    </>
};
export default App;