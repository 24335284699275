/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Toast from 'react-bootstrap/Toast';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { IconDatabaseImport , IconCloudUpload, IconRefresh, IconX, IconAlertTriangle } from '@tabler/icons';
import { CSVReader } from 'react-papaparse';
import _ from 'lodash';
import { ButtonClipLoader } from '../../components/Loading';
import SelectTopic from './SelectTopic';
import SelectLanguage from './SelectLanguage';
import SelectAttribute from './SelectAttribute';
import { postProducts } from './services';
const buttonRef = React.createRef()
const ImportProduct = ({ fetchDataProduct, listTopic, listA }) => {
    const { register, handleSubmit } = useForm();
    const [topics, setTopics] = useState([]);
    const [topicName, setTopicName] = useState('');
    const [languageCode, setLanguageCode] = useState('en');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [errorFile, setErrorFile] = useState('');
    const [headers, setHeaders] = useState([]);
    const [content, setContent] = useState([]);
    const [products, setProducts] = useState();
    const [headerMapping, setHeaderMapping] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errShow, setErrShow] = useState(false);
    const [errMessage, setErrMessage] = useState('');
    useEffect(() => {
        if (!_.isUndefined(listTopic) && Array.isArray(listTopic) && !_.isEmpty(listTopic)) {
            setTopics(listTopic);
        } else {
            setTopics([]);
        }
    }, [listTopic]);
    const toggleErrShow = () => setErrShow(!errShow);
    const onSubmit = async (data) => {
        setIsLoading(true);
        try {
            if (_.isUndefined(products) || 
                _.isNull(products) || _.isNull(headers) || _.isNull(headerMapping) ||
                _.isEmpty(products) || _.isEmpty(headers) || _.isEmpty(headerMapping)) {
                throw new TypeError('Data is not empty!');
            }
            if (_.size(headers) !== _.size(headerMapping)) {
                throw new TypeError('Require all select mapping title!');
            }
            const valueHeaders = _.map(headerMapping, (item, key) => { 
                item.title = headers[key]
                return item;
            });
            const dataProducts = handleProducts(valueHeaders, content);
            setProducts(dataProducts);
            const textgen = {
                description: data.description,
                meta_description: data.metaDescription,
                meta_title: data.metaTitle,
                meta_keyword: data.metaKeyword
            };
            const dataRequest = {
                topicName: topicName,
                languageCode: languageCode,
                textgen,
                products: dataProducts
            };
            const result = await postProducts(dataRequest);
            setIsLoading(false);
            if (!_.has(result, 'error')) {
                toast.success('Successfully');
                handleClose();
                fetchDataProduct();
                setErrShow(false);
                setErrMessage(false);
            } else {
                toast.error(result.errMessage || 'Import failed!');
                throw new TypeError(result.errMessage || 'Import failed!');
            }
        } catch (e) {
            setIsLoading(false);
            setErrShow(true);
            setErrMessage(e.message);
        }
    }
    const handleOpenDialog = (e) => {
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    }
    const handleOnFileLoad = (data, csvFile) => {
        const pattern = '^.+\\.(csv)$'
        const regex = new RegExp(pattern)
        if (regex.test(csvFile.name)) {
            setErrorFile([]);
            if (!_.isNull(data)) {
                const dataHeaders = [...data[0].data];
                const dataContent = [...data.slice(1)]
                setHeaders(dataHeaders);
                setContent(dataContent);
                const dataProducts = handleProducts(dataHeaders, dataContent);   
                setProducts(dataProducts);
            }
        } else {
            setErrorFile('Import has been accept only csv file');
        }
    }
    const handleProducts = (valueHeaders, valueContent) => {
        // const dataHeaders = _.map(valueHeaders, value => _.snakeCase(_.lowerCase(value.value || value)));
        const dataProducts = [];
        _.forEach(valueContent, (value) => {
            const attributes = [];
            value.data.map((value, key) => {
                if (key > 0 && !_.isEmpty(_.trim(value))) {
                    let selectOption = [];
                    if (!_.isUndefined(valueHeaders[key].id && valueHeaders[key].selectOption)) {
                        selectOption = [...valueHeaders[key].selectOption];
                    }
                    if (Array.isArray(selectOption) && _.size(selectOption) > 0) {
                        const index = _.findIndex(selectOption, {'value': _.trim(value)});
                        if (index > -1) {
                            selectOption.splice(index, 1, _.trim(value));
                        } else {
                            selectOption.push({ value: _.trim(value) });
                        }
                    } else {
                        selectOption = [_.trim(value)]
                    }
                    const elementAttribute = { value: _.trim(value) };
                    if (!_.isUndefined(valueHeaders[key].id)) { elementAttribute.id = valueHeaders[key].id; }
                    if (!_.isUndefined(valueHeaders[key].code)) { elementAttribute.code = valueHeaders[key].code; }
                    if (!_.isUndefined(valueHeaders[key].label)) { elementAttribute.label = valueHeaders[key].label; }
                    if (!_.isUndefined(valueHeaders[key].title)) { elementAttribute.title = valueHeaders[key].title; }
                    if (!_.isUndefined(valueHeaders[key].typeData)) { elementAttribute.typeData = valueHeaders[key].typeData || 'STRING'; }
                    if (!_.isUndefined(valueHeaders[key].selectOption)) { elementAttribute.selectOption = selectOption; }
                    attributes.push(elementAttribute);
                }
            });
            dataProducts.push({
                sku: _.trim(value.data[0]),
                attributes: attributes
            });
        });
        return dataProducts;
    }
    const handleOnError = (err) => {
        console.log(err)
    }
    const handleOnRemoveFile = (data) => {
        setIsLoading(false);
        setErrShow(false);
        setErrorFile([]);
        setHeaders(data);
        setProducts(data);
        setHeaderMapping(data);
    }
    const handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e)
        }
    }
    const onLoadHeaders = (data) => {
        if (!_.isNull(headerMapping) && !_.isEmpty(headerMapping)) {
            const index = _.findIndex(headerMapping, { 'keyHeader': data.keyHeader });
            if (index > -1) {
                headerMapping.splice(index, 1, data);
            } else {
                headerMapping.push(data);
            }
            setHeaderMapping(_.sortBy(headerMapping, ['keyHeader']));
        } else {
            setHeaderMapping([data]);
        }
    }
    return <>
        <code title='import CSV' className='p-1 me-2 cl-pointer' onClick={handleShow}><IconDatabaseImport color={'#1d60b0'} /></code>
        <Modal show={show} onHide={handleClose} className='modal-blur' centered size='lg'>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>Import</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <div className='col-auto'>
                            <div className='d-table'>
                                <Form.Switch
                                    className='mb-3'
                                    type='switch'
                                    autoFocus
                                    defaultChecked={true}
                                    name='description'
                                    label='Description'
                                    {...register('description')}
                                />
                                <Form.Switch
                                    className='mb-3'
                                    type='switch'
                                    autoFocus
                                    defaultChecked={true}
                                    name='metaDescription'
                                    label='Meta description'
                                    {...register('metaDescription')}
                                />
                                <Form.Switch
                                    className='mb-3'
                                    type='switch'
                                    autoFocus
                                    defaultChecked={true}
                                    name='metaTitle'
                                    label='Meta Title'
                                    {...register('metaTitle')}
                                />
                                <Form.Switch
                                    className='mb-3'
                                    type='switch'
                                    autoFocus
                                    defaultChecked={true}
                                    name='metaKeyword'
                                    label='Meta Keyword'
                                    {...register('metaKeyword')}
                                />
                            </div>
                        </div>
                        <div className='col'>
                            <Form.Group className='mb-3'>
                                <Form.Label>Theme: </Form.Label>
                                <SelectTopic topics={topics} handleTopic={setTopicName} />
                            </Form.Group>
                            <Form.Group controlId='language'>
                                <Form.Label>Language generate: </Form.Label>
                                <SelectLanguage handleLanguage={setLanguageCode} />
                            </Form.Group>
                        </div>
                    </Row>
                    <CSVReader
                        accept={'.csv, text/csv'}
                        ref={buttonRef}
                        onFileLoad={handleOnFileLoad}
                        onError={handleOnError}
                        noClick
                        noDrag
                        onRemoveFile={handleOnRemoveFile}
                    >
                        {({ file }) => (
                            <aside style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                <Button
                                    type='button'
                                    className='btn-outline-primary'
                                    onClick={handleOpenDialog}
                                    style={{ borderRadius: 0, width: '40%' }}>
                                    <IconCloudUpload /> Browse file .csv
                                </Button>
                                <div style={{
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    borderColor: '#ccc',
                                    lineHeight: 2.5,
                                    paddingLeft: '10px',
                                    width: '60%'
                                }}>
                                    {file && file.name}
                                </div>
                                <Button
                                    type='button'
                                    className='btn-outline-secondary'
                                    style={{ borderRadius: 0 }}
                                    onClick={handleRemoveFile}><IconRefresh style={{ margin: '0px' }} /></Button>
                            </aside>
                        )}
                    </CSVReader>
                    <Toast show={errShow} className='alert alert-danger alert-dismissible mt-3' role='alert'>
                        <div className='d-flex'>
                            <div><IconAlertTriangle className='alert-icon' /></div>
                            <div>
                                <h4 className='alert-title'>Something went wrong</h4>
                                <div className='text-muted'>{errMessage}</div>
                            </div>
                        </div>
                        <a className='btn-close' onClick={toggleErrShow} />
                    </Toast>
                    <Row className='mapping-csv mt-3'>
                        {headers && <div className='col-auto'>
                            <h4>Mapping attribute:</h4>
                        </div>}
                        {headers && headers.map((header, i) => {
                            return <div className='flex-container mt-2' key={i}>
                                <Col><code>{header}</code></Col>
                                <Col><SelectAttribute keyHeader={i} attributes={listA} setHeaders={onLoadHeaders} /></Col>
                            </div>
                        })}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='link-secondary' onClick={handleClose}><IconX />Cancel</Button>
                    <ButtonClipLoader
                        disabled={false}
                        variant='primary'
                        icon={<IconCloudUpload />}
                        isLoading={isLoading}
                        type='submit'
                        label='Import'
                        onClick={handleSubmit(onSubmit)}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    </>
}

export default ImportProduct;