import fetch from 'isomorphic-fetch';
import * as Utils from '../../utils';
import { API_ROOT } from '../../config';

const BASE_URL = API_ROOT;

export const getProducts = async (params) => {
    try {
        const response = await fetch(`${BASE_URL}/ai-products?${Utils.ObjectToQueryString(params)}`, {
            method: 'GET',
            headers: Utils.commonHeaders(),
        });
        return Utils.handleNon200s(response);
    } catch (error) {
        console.log('error prod TypeError: ', error.TypeError);
        return {
            error: true,
            errMessage: 'ERR_CONNECTION_TIMED_OUT'
        }
    }
    
}

export const exportProducts = async (data) => {
    const response = await fetch(`${BASE_URL}/ai-products/exports`, {
        method: 'POST',
        headers: Utils.commonHeaders(),
        body: JSON.stringify(data)
    });
    return Utils.handleNon200s(response);
}

export const removeProducts = async (data) => {
    const response = await fetch(`${BASE_URL}/ai-products/remove`, {
        method: 'POST',
        headers: Utils.commonHeaders(),
        body: JSON.stringify(data)
    });
    return Utils.handleNon200s(response);
}

export const getProduct = async (params) => {
    const response = await fetch(`${BASE_URL}/ai-products/${params.id}`, {
        method: 'GET',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}

export const postProduct = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/ai-products`, {
            method: 'POST',
            headers: Utils.commonHeaders(),
            body: JSON.stringify(data)
        });
        return Utils.handleNon200s(response);
    } catch (error) {
        console.log('error: ', error);
        return false;
    }
};

export const postProducts = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/ai-products/generate`, {
            method: 'POST',
            headers: Utils.commonHeaders(),
            body: JSON.stringify(data)
        });
        return Utils.handleNon200s(response);
    } catch (error) {
        console.log('error: ', error);
        return false;
    }
};

export const putProduct = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/ai-products/${data.id}`, {
            method: 'PUT',
            headers: Utils.commonHeaders(),
            body: JSON.stringify(data)
        });
        return Utils.handleNon200s(response);
    } catch (error) {
        console.log('error: ', error);
        return false;
    }
};

export const deleteProduct = async (params) => {
    const response = await fetch(`${BASE_URL}/ai-products/${params.id}`, {
        method: 'DELETE',
        headers: Utils.commonHeaders(),
    });
    return Utils.handleNon200s(response);
}