/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { IconInfoSquare, IconDatabaseExport } from '@tabler/icons';
import { Modal, Form, Button } from 'react-bootstrap';
import moment from 'moment';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver'
import _ from 'lodash';
import { exportProducts } from './services';
const ExportProduct = ({ ids }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        handleExport(ids);
    }
    const handleExport = async (ids) => {
        const params = {
            ids: ids
        };
        if (_.isUndefined(ids)) {
            delete params.ids;
        }
        const resultProducts = await exportProducts(params);
        if (resultProducts) {
            const dateTime = moment().format('DD-MM-YYYY');
            const headers = [
                { title: 'Sku', dataKey: 'sku' },
                { title: 'Description', dataKey: 'description' },
                { title: 'Meta Description', dataKey: 'metaDescription' },
                { title: 'Meta title', dataKey: 'metaTitle' },
                { title: 'Meta Keywords', dataKey: 'keyword' }
            ];
            let listDataExport = [];
            if (Array.isArray(resultProducts.data) && _.size(resultProducts.data) > 0) {
                resultProducts.data.map((item) => {
                    const content = JSON.parse(item.content);
                    return listDataExport.push({
                        sku: item.sku,
                        description: !_.isNull(content) ? content.description : '',
                        metaDescription: !_.isNull(content) ? content.metaDescription : '',
                        metaTitle: !_.isNull(content) ? content.metaTitle : '',
                        keyword: !_.isNull(content) ? content.keyword : ''
                    });
                });
                const ws = XLSX.utils.book_new()
                XLSX.utils.sheet_add_aoa(ws, [_.map(headers, 'title')])
                XLSX.utils.sheet_add_json(ws, listDataExport, { origin: 'A2', skipHeader: true })
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true })
                const finalData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
                FileSaver.saveAs(finalData, 'export-products-' + dateTime + '.xlsx');
                toggle();
            }
        }
    }
    return <>
        <span title='Export' onClick={toggle}><IconDatabaseExport className='me-2' color={'#1d60b0'} />Export</span>
        <Modal show={isOpen} onHide={toggle} className='modal-blur' size='sm' centered>
            <Form onSubmit={handleSubmit}>
                <button type='button' className='btn-close' onClick={toggle}></button>
                <div className='modal-status bg-info'></div>
                <div className='modal-body py-2'>
                    <h4><IconInfoSquare className='mb-2 text-primary icon-md' /> Export products</h4>
                    <div className='text-muted'>
                        {/*  */}
                    </div>
                </div>
                <div className='modal-footer'>
                    <div className='w-100'>
                        <div className='row'>
                            <div className='col'>
                                <Button variant='white w-100' onClick={toggle}>Cancel</Button>
                            </div>
                            <div className='col'>
                                <Button variant='primary w-100' type='submit'>Export</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    </>
}
export default ExportProduct;