/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import _ from 'lodash';
import ExportProduct from './ExportProduct';
import RemoveProduct from './RemoveProduct';

const App = ({ productChecked, fetchDataProduct }) => {
    const [loadings, setLoadings] = useState([]);
    const productSelected = _.filter(productChecked, 'isChecked');
    const ids = _.map(productSelected, p => p.id);
    const items = [
        {
            onClick: () => enterLoading(1),
            icon: <ExportProduct ids={ids} />,
            label: '',
            key: '1',
        },
        {
            onClick: () => enterLoading(2),
            icon: <RemoveProduct fetchDataProduct={fetchDataProduct} ids={ids} />,
            label: '',
            key: '2',
        },
    ];
    const enterLoading = (index) => {
        setLoadings((state) => {
            const newLoadings = [...state];
            newLoadings[index] = true;
            return newLoadings;
        });
        setTimeout(() => {
            setLoadings((state) => {
                const newLoadings = [...state];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 3000);

    };
    return (
        <Space direction="vertical">
            <Dropdown.Button
                icon={<DownOutlined />}
                loading={loadings[1] || loadings[2]}
                menu={{
                    items,
                }}
                // onClick={() => enterLoading(1)}
            >
                Action
            </Dropdown.Button>
        </Space>
    );
};
export default App;