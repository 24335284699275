/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconMessage2, IconMail, IconWorldWww, IconZip, IconMapPin } from '@tabler/icons';
import { IoToggle, IoToggleOutline } from 'react-icons/io5';
import { Modal, Button } from 'react-bootstrap';

const ShowTopic = ({ data }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    }
    return <>
        <span className='text-primary me-2 cl-pointer' onClick={toggle}><IconMessage2 /></span>
        <Modal show={isOpen} onHide={toggle} className='modal-blur' size='sm' centered>
            <div>
                <button type='button' className='btn-close' onClick={toggle}></button>
                <div className='modal-body py-4'>
                    <div className='text-muted'>
                        {data.name && <p>{data.name}</p>}
                    </div>
                </div>
                <div className='modal-footer'>
                    <div className='w-100'>
                        <div className='row'>
                            <div className='col'>
                                <Button variant='white w-100' onClick={toggle}>Close</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}

export default ShowTopic;