import FullPageLayout from './containers/Layout/FullPageLayout';
import PromptPage from './containers/Prompt';
import ProfilePage from './containers/Profile';
import ProfileUpdate from './containers/Profile/update';
import TopicPage from './containers/Topics';
import ProductPage from './containers/Products';
import ProductManualPage from './containers/ProductsManual';
import NewProductManualPage from './containers/ProductsManual/NewProduct';
import ChatPage from './containers/ChatPoolp';
import ChatPageDetail from './containers/ChatPoolp/detail';
import AttributesPage from './containers/Attributes';
import CategoryPage from './containers/Categories';
import CategoryDetailPage from './containers/Categories/detail';
import TemplatePage from './containers/Templates';
import TemplateDetailPage from './containers/Templates/detail';
import GeneratorPage from './containers/Generators';
import EntityPage from './containers/Entity';
import UserPage from './containers/Users';


/**
 * only for authorize route
 * configuration { path, component, layout }
 */
const routes = [
    { path: '/', component: PromptPage, layout: FullPageLayout },
    { path: '/profile', component: ProfilePage, layout: FullPageLayout },
    { path: '/profile-edit', component: ProfileUpdate, layout: FullPageLayout },
    { path: '/topics', component: TopicPage, layout: FullPageLayout },
    { path: '/products', component: ProductPage, layout: FullPageLayout },
    { path: '/products-manual', component: ProductManualPage, layout: FullPageLayout },
    { path: '/products-manual/add', component: NewProductManualPage, layout: FullPageLayout },
    { path: '/entities', component: EntityPage, layout: FullPageLayout },
    { path: '/attributes', component: AttributesPage, layout: FullPageLayout },
    { path: '/users', component: UserPage, layout: FullPageLayout },
    { path: '/chat', component: ChatPage, layout: FullPageLayout },
    { path: '/chat/:id', component: ChatPageDetail, layout: FullPageLayout },
    { path: '/profile', component: ProfilePage, layout: FullPageLayout },
    { path: '/categories', component: CategoryPage, layout: FullPageLayout },
    { path: '/categories/:id', component: CategoryDetailPage, layout: FullPageLayout },
    { path: '/templates', component: TemplatePage, layout: FullPageLayout },
    { path: '/templates/:id', component: TemplateDetailPage, layout: FullPageLayout },
    { path: '/generations', component: GeneratorPage, layout: FullPageLayout },
]

export default routes;