/* eslint-disable react/prop-types */
import React from 'react';
import { Select } from 'antd';

const App = ({ handleLanguage }) => {
    const handleChange = (value) => {
        handleLanguage(value);
    };

    const options = [
        {
            value: 'en',
            label: 'EN',
        },
        {
            value: 'fr',
            label: 'FR',
        },
    ];
    
    return <Select
        dropdownStyle={{ zIndex: 9999 }}
        defaultValue='en'
        onChange={handleChange}
        options={options}
    />
};
export default App;