/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { Table, Form } from 'react-bootstrap';
import _ from 'lodash';
import { IconArrowUp, IconArrowDown, IconSearch, IconX, IconCircleLetterE } from '@tabler/icons';
import { formatDateString } from '../../utils';
import * as Storage from '../../storage';
import Header from '../../components/Header';
import Aside from '../../components/Aside';
import Footer from '../../components/Footer';
import NoData from '../../components/NoData';
import AddEntity from './AddEntity';
import UpdateEntity from './modalUpdate';
import ConfirmRemove from './ConfirmRemove';
import ShowEntity from './modalDetail';
import Paging from './Paging';
import { getEntities, putEntityStatus } from './services';

const LIMIT = 20;
const PAGE_DISPLAY = 5;

const EntityPage = (props) => {
    const userInfo = JSON.parse(localStorage.getItem(Storage.USER_INFO));
    const [entities, setEntities] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [pagination, setPagination] = useState({ limit: LIMIT, offset: 0, totalCount: 20 });
    const [order, setOrder] = useState('ASC');
    const [sortBy, setSortBy] = useState('createdAt');
    // Init data entity
    let isInit = true;
    useEffect(() => {
        if (isInit) {
            isInit = false
            fetchDataEntity();
        }
    }, []);

    const fetchDataEntity = async (params = {}) => {
        const pageActive = params.pageActive || 1;
        params.order = order;
        params.limit = LIMIT;
        params.offset = (pageActive - 1) * LIMIT;
        if (params.name === '') {
            delete params.name;
        } else {
            params.name = searchText;
        }
        if (params.sortBy) {
            setSortBy(params.sortBy);
            if (params.order === 'ASC') {
                setOrder('DESC');
            } else {
                setOrder('ASC');
            }
        }
        const response = await getEntities(params);
        if (response) {
            if (_.has(response, 'data')) {
                setEntities(response.data);
            }
            if (_.has(response, 'pagination')) {
                setPagination(response.pagination);
            }
        }
        return;
    }

    const ItemSort = (item) => {
        let itemDom = <IconArrowDown size={12} stroke={1} />;
        if (item === sortBy) {
            if (order === 'ASC') {
                itemDom = <IconArrowUp size={12} stroke={1} />;
            }
        }
        return itemDom;
    }
    const handleSearch = (event) => {
        event.preventDefault();
        fetchDataEntity({ name: searchText });
    }
    const handleReset = () => {
        setSearchText('');
        fetchDataEntity({ name: '' });
    }
    const handleChange = async(e) => {
        const { checked } = e.target;
        const { id } = e.target.dataset;
        const response = await putEntityStatus({
            id: Number(id),
            status: checked
        });
        if (response.error) {
            toast.error(response.errMessage);
            let tempEntity = entities.map((entity) => {
                return entity.id === Number(id) ? { ...entity, status: !checked } : entity
            });
            setEntities(tempEntity);
        } else {
            toast.success('Successfully');
            let tempEntity = entities.map((entity) => {
                return entity.id === Number(id) ? { ...entity, status: checked } : entity
            });
            setEntities(tempEntity);
        }
    }
    return (<>
        <Aside match={props.match} />
        <Header userInfo={userInfo} />
        <div className='page-wrapper mt-55'>
            <div className='page-body'>
                <div className='row row-deck row-cards'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title col'>
                                    <IconCircleLetterE className='mb-2'/> List of entities
                                </h3>
                                <div className='col-auto'>
                                    <AddEntity fetchDataEntity={fetchDataEntity} />
                                </div>
                            </div>
                            <div className='card-body border-bottom py-3'>
                                <div className='d-flex'>
                                    <Form onSubmit={handleSearch} className='ms-auto text-muted d-inline-block'>
                                        <div className='input-group input-group-flat'>
                                            <input type='text' name='search' className='form-control' placeholder='Search entity…' onChange={(e) => setSearchText(e.target.value)} value={searchText} />
                                            <span className='input-group-text'>
                                                {!_.isEmpty(searchText) && <Link className='link-secondary' to={'#'} title='Clear search' data-bs-toggle='tooltip' onClick={handleReset}><IconX /></Link>}
                                                <Link className='link-secondary' to={'#'} onClick={handleSearch}><IconSearch /></Link>
                                            </span>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <div className='table-responsive' style={{ minHeight: '300px' }}>
                                <Table className='card-table table-vcenter text-nowrap datatable'>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Logo</th>
                                            <th>Name<Link to={'#'} onClick={() => fetchDataEntity({ sortBy: 'name' })}>{ItemSort('name')}</Link></th>
                                            <th>Website</th>
                                            <th>Creation date<Link to={'#'} onClick={() => fetchDataEntity({ sortBy: 'createdAt' })}>{ItemSort('createdAt')}</Link></th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(entities) && _.size(entities) > 0 ? entities.map((row, i) =>
                                            <tr key={row.id}>
                                                <td><span className='text-muted'>{pagination.offset + i + 1}</span></td>
                                                <td>{row.logo && row.logo}</td>
                                                <td>{row.name && row.name}</td>
                                                <td>{row.website && row.website}</td>
                                                <td>{formatDateString(row.createdAt)}</td>
                                                <td>
                                                    <Form.Switch
                                                        type='switch'
                                                        data-id={row.id}
                                                        checked={row.status}
                                                        onChange={handleChange}
                                                    />
                                                </td>
                                                <td>
                                                    <ShowEntity data={row} />
                                                    <UpdateEntity entity={row} fetchDataEntity={fetchDataEntity} />
                                                    <ConfirmRemove
                                                        title='Confirm deletion'
                                                        message='Are you sure you want to delete this item?'
                                                        id={row.id}
                                                        fetchDataEntity={fetchDataEntity}
                                                    />
                                                </td>
                                            </tr>
                                        ) : <NoData />}
                                    </tbody>
                                </Table>
                            </div>
                            <div className='card-footer d-flex align-items-center'>
                                {!_.isUndefined(pagination) &&
                                <Paging
                                    activePage={pagination.offset / LIMIT + 1}
                                    itemsCountPerPage={pagination.limit || LIMIT}
                                    totalItemsCount={pagination.totalCount}
                                    pageRangeDisplayed={PAGE_DISPLAY}
                                    requestList={fetchDataEntity}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position='top-center'
                reverseOrder={false}
            />
        </div>
        <Footer/>
    </>)
}

export default EntityPage;