/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { IconAlertTriangle, IconTrash } from '@tabler/icons';
import { Modal, Form, Button } from 'react-bootstrap';
import _ from 'lodash';
import { removeProducts } from './services';
const RemoveProduct = ({ fetchDataProduct, ids }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!_.isUndefined(ids) && !_.isEmpty(ids)) {
            const resultRemove = await removeProducts({ ids });
            if (resultRemove) {
                fetchDataProduct();
                toggle();
            }
        }
    }
    return <>
        <span className='text-danger' title='remove' onClick={toggle}><IconTrash className='me-2' /> Trash</span>
        <Modal show={isOpen} onHide={toggle} className='modal-blur' size='sm' centered>
            <Form onSubmit={handleSubmit}>
                <button type='button' className='btn-close' onClick={toggle}></button>
                <div className='modal-status bg-danger'></div>
                <div className='modal-body text-center py-4'>
                    <IconAlertTriangle className='mb-2 text-danger icon-lg' />
                    <h3>Remove</h3>
                    <div className='text-muted'></div>
                </div>
                <div className='modal-footer'>
                    <div className='w-100'>
                        <div className='row'>
                            <div className='col'>
                                <Button variant='white w-100' onClick={toggle}>Cancel</Button>
                            </div>
                            <div className='col'>
                                <Button variant='danger w-100' type='submit'>Remove</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    </>
}

export default RemoveProduct;