/* eslint-disable react/prop-types */
import React from 'react';
import { IconFileDownload } from '@tabler/icons';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver'
import _ from 'lodash';
const FileExample = () => {
    const handleExport = () => {
        const headers = [
            { title: 'Sku', dataKey: 'sku' },
            { title: 'Name', dataKey: 'name' },
            { title: 'Color', dataKey: 'color' },
            { title: 'Size', dataKey: 'size' },
            { title: 'Materials', dataKey: 'material' }
        ];
        const content = [
            { sku: 'BAG01', name: 'Adidas Marvel Avengers Backpack Kids', color: 'Victory Blue', size: '12 cm x 26 cm x 34 cm', material: '100% polyester (recycled)' },
            { sku: 'CLT03', name: 'Rolling Links Polo Shirt', color: 'Bold Green', size: 'A/M', material: '70% cotton, 30% polyester (recycled)' }
        ]
        if (Array.isArray(content) && _.size(content) > 0) {
            const ws = XLSX.utils.book_new()
            XLSX.utils.sheet_add_aoa(ws, [_.map(headers, 'title')])
            XLSX.utils.sheet_add_json(ws, content, { origin: 'A2', skipHeader: true })
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true })
            const finalData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
            FileSaver.saveAs(finalData, 'Example-import.xlsx');
        }
    }
    return <span className='cl-pointer' title='Example' onClick={handleExport} style={{ color: '#1d60b0' }}><IconFileDownload/>Example.xlsx</span>
}
export default FileExample;