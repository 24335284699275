/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Row } from 'react-bootstrap';
import { SSEProvider } from 'react-hooks-sse';
import _ from 'lodash';
import { IconSearch, IconX } from '@tabler/icons';
import { getProducts } from './services';
import { getAttributes } from '../Attributes/services';
import { getTopics } from '../Topics/services';
import * as Storage from '../../storage';
import { ObjectToQueryString } from '../../utils';
import Header from '../../components/Header';
import Aside from '../../components/Aside';
import Footer from '../../components/Footer';
import AddProduct from './AddProduct';
import ImportProduct from './ImportProduct';
import ActionProduct from './ActionProduct';
import FileExample from './FileExample';
import DataTable from './DataTable';
import Paging from './Paging';
import { API_ROOT } from '../../config';

const BASE_URL = API_ROOT;
const eventUrl = `${BASE_URL}/ai-product/event`;
const LIMIT = 20;
const PAGE_DISPLAY = 5;

const ProductPage = (props) => {
    const jwtToken = JSON.parse(localStorage.getItem(Storage.JWT_TOKEN))
    const userInfo = JSON.parse(localStorage.getItem(Storage.USER_INFO));
    const [products, setProducts] = useState([]);
    const [productChecked, setProductChecked] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [topics, setTopics] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [pagination, setPagination] = useState({ limit: LIMIT, offset: 0, totalCount: 20 });
    const [pageActive, setPageActive] = useState(1);
    const [order, setOrder] = useState('ASC');
    const [sortBy, setSortBy] = useState('createdAt');
    const [paramRequest, setParamRequest] = useState({});
    // Init data entity
    let isInit = true;
    useEffect(() => {
        if (isInit) {
            isInit = false
            fetchDataProduct();
            fetchDataAttribute();
            fetchDataTopic();
        }
    }, []);
    const fetchDataAttribute = async() => {
        const params = {
            limit: -1
        }
        const response = await getAttributes(params);
        if (response && _.has(response, 'data')) {
            setAttributes(response.data);
        }
        return;
    }
    const fetchDataTopic = async () => {
        const params = {
            limit: -1
        }
        const response = await getTopics(params);
        if (response && _.has(response, 'data')) {
            setTopics(response.data);
        }
        return;
    }
    const fetchDataProduct = async (params = {}) => {
        const pageA = params.pageActive || 1
        setPageActive(pageA);
        params.order = order;
        params.limit = LIMIT;
        params.offset = (pageA - 1) * LIMIT;
        if (params.name === '') {
            delete params.name;
        } else {
            params.name = searchText;
        }
        if (params.sortBy) {
            setSortBy(params.sortBy);
            if (params.order === 'ASC') {
                setOrder('DESC');
            } else {
                setOrder('ASC');
            }
        }
        const response = await getProducts(params);
        if (response) {
            setParamRequest(params);
            if (_.has(response, 'data')) {
                const ids = new Set(productChecked.map(d => d.id));
                let newData = response.data;
                newData = _.map(newData, item => {
                    if (ids.has(item.id)) {
                        item = productChecked.filter(d => d.id === item.id)[0];
                    }
                    return item
                });
                setProducts(newData);
            }
            if (_.has(response, 'pagination')) {
                setPagination(response.pagination);
            }
        }
        return;
    }
    
    const handleSearch = (event) => {
        event.preventDefault();
        fetchDataProduct({ name: searchText });
    }
    const handleReset = () => {
        setSearchText('');
        fetchDataProduct({ name: '' });
    }
    return (<>
        <Aside match={props.match} />
        <Header userInfo={userInfo} />
        <div className='page-wrapper mt-55'>
            <div className='page-body'>
                <div className='row row-deck row-cards'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title col'>List of products</h3>
                                <div className='col-auto'>
                                    <ActionProduct fetchDataProduct={fetchDataProduct} productChecked={productChecked} />
                                </div>
                            </div>
                            <div className='card-body border-bottom py-3'>
                                <Row>
                                    <div className='col'>
                                        <Form onSubmit={handleSearch} className='ms-auto text-muted d-inline-block'>
                                            <div className='input-group input-group-flat'>
                                                <input type='text' name='search' className='form-control' placeholder='Search product…' onChange={(e) => setSearchText(e.target.value)} value={searchText} />
                                                <span className='input-group-text'>
                                                    {!_.isEmpty(searchText) && <Link className='link-secondary' to={'#'} title='Clear search' data-bs-toggle='tooltip' onClick={handleReset}><IconX /></Link>}
                                                    <Link className='link-secondary' to={'#'} onClick={handleSearch}><IconSearch /></Link>
                                                </span>
                                            </div>
                                        </Form>
                                    </div>
                                    <div className='col-auto'>
                                        <AddProduct fetchDataProduct={fetchDataProduct} listTopic={topics} listA={attributes} />
                                        <ImportProduct fetchDataProduct={fetchDataProduct} listTopic={topics} listA={attributes} />
                                        <FileExample />
                                    </div>
                                </Row>
                            </div>
                            <div className='table-responsive' style={{ minHeight: '300px' }}>
                                <SSEProvider endpoint={`${eventUrl}?${ObjectToQueryString({ token: jwtToken })}`}>
                                    <DataTable userInfo={userInfo} 
                                        productChecked={productChecked}
                                        setProductChecked={setProductChecked}
                                        products={products} 
                                        setProducts={setProducts}
                                        paramRequest={paramRequest} 
                                        fetchDataProduct={fetchDataProduct} 
                                        pagination={pagination} 
                                        order={order}
                                        sortBy={sortBy}
                                        attributes={attributes}
                                        listTopic={topics} />
                                </SSEProvider>
                            </div>
                            <div className='card-footer d-flex align-items-center'>
                                {!_.isUndefined(pagination) &&
                                    <Paging
                                        activePage={pageActive}
                                        itemsCountPerPage={pagination.limit || LIMIT}
                                        totalItemsCount={pagination.totalCount}
                                        pageRangeDisplayed={PAGE_DISPLAY}
                                        requestList={fetchDataProduct}
                                    />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </>);
};

export default ProductPage;